import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loader } from 'kl-b2c-ui-kit';
import {
    ConfirmationProvider,
    CourseProvider,
    EventProvider,
    MobileMenuStateProvider,
    ProfessionProvider,
} from 'contexts';
import { AppLayout } from 'containers/app-layout';

const AboutPage = lazy(() => import('../pages/about'));
const NewsListPage = lazy(() => import('../pages/news-list'));
const NewsItemPage = lazy(() => import('../pages/news-item'));
const EventsList = lazy(() => import('../pages/events-list'));
const EventsItem = lazy(() => import('../pages/events-item'));
const NotFoundPage = lazy(() => import('../pages/not-found'));
const HomePage = lazy(() => import('../pages/home'));
const CourseItem = lazy(() => import('../pages/courses-item'));
const ProfessionItem = lazy(() => import('../pages/profession-item'));
const CourseList = lazy(() => import('../pages/courses-list'));
const FreeCourse = lazy(() => import('../pages/free-course'));
const AcademyAlliance = lazy(() => import('../pages/academy-alliance'));
const ForBusinesses = lazy(() => import('../pages/for-businesses'));
const ForGovernments = lazy(() => import('../pages/for-governments'));
const KidsCyberResilience = lazy(() =>
    import('../pages/kids-cyber-resilience')
);
const PrivacyPolicy = lazy(() => import('../pages/privacy-policy'));
const Confirmation = lazy(() => import('../pages/confirmation'));

const AppRouter: React.FC = (): JSX.Element => (
    <MobileMenuStateProvider>
        <AppLayout>
            <Suspense fallback={<Loader variant={'fixed'} size={'large'} />}>
                <Routes>
                    <Route
                        path={'/'}
                        element={
                            <EventProvider>
                                <HomePage />
                            </EventProvider>
                        }
                    />
                    <Route
                        path={'/kids-cyber-resilience'}
                        element={
                            <EventProvider>
                                <KidsCyberResilience />
                            </EventProvider>
                        }
                    />
                    <Route path={'/about'} element={<AboutPage />} />
                    <Route path={'/news/:id'} element={<NewsItemPage />} />
                    <Route path={'/news'} element={<NewsListPage />} />
                    <Route
                        path={'/academy-alliance'}
                        element={<AcademyAlliance />}
                    />
                    <Route
                        path={'/events/:id'}
                        element={
                            <EventProvider>
                                <EventsItem />
                            </EventProvider>
                        }
                    />
                    <Route
                        path={'/events'}
                        element={
                            <EventProvider>
                                <EventsList />
                            </EventProvider>
                        }
                    />
                    {process.env.REACT_APP_LOCALE === 'ru' && (
                        <Route
                            path={'course/free-course'}
                            element={
                                <CourseProvider>
                                    <FreeCourse />
                                </CourseProvider>
                            }
                        />
                    )}
                    <Route
                        path={'course/:id'}
                        element={
                            <CourseProvider>
                                <CourseItem />
                            </CourseProvider>
                        }
                    />
                    <Route
                        path={
                            'courses/:skills?/:languages?/:directions?/:studyForms?/:forWhom?'
                        }
                        element={
                            <ProfessionProvider>
                                <CourseProvider>
                                    <CourseList />
                                </CourseProvider>
                            </ProfessionProvider>
                        }
                    />
                    <Route
                        path={'profession/:id'}
                        element={
                            <ProfessionProvider>
                                <ProfessionItem />
                            </ProfessionProvider>
                        }
                    />
                    <Route
                        path={'for-businesses'}
                        element={
                            <CourseProvider>
                                <ForBusinesses />
                            </CourseProvider>
                        }
                    />
                    <Route
                        path={'for-governments'}
                        element={
                            <CourseProvider>
                                <ForGovernments />
                            </CourseProvider>
                        }
                    />
                    <Route
                        path={'confirmation/:agreementId'}
                        element={
                            <ConfirmationProvider>
                                <Confirmation />
                            </ConfirmationProvider>
                        }
                    />
                    <Route
                        path={'privacy-policy'}
                        element={<PrivacyPolicy />}
                    />
                    <Route path={'*'} element={<NotFoundPage />} />
                </Routes>
            </Suspense>
        </AppLayout>
    </MobileMenuStateProvider>
);
export default AppRouter;
